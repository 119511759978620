@import url(https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;1,300;1,400;1,500&display=swap);
@import url(https://fonts.googleapis.com/css2?family=League+Gothic&display=swap);
html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Ubuntu", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

p {
  line-height: 27px;
}

::selection {
  background-color: #00aaff;
  color: white;
}

.homewrapper {
  overflow-x: hidden;
}

.homeheader {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: url(/static/media/headerbackground.d55cdcdf.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 1100px) {
  .homeheader {
    height: 75vh;
  }
}

.navigationwrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 1px solid #e6e5e5;
  height: 100px;
  position: relative;
}

.burger {
  display: none;
}

.navigationwrapper img {
  width: 90px;
  object-fit: contain;
  margin-left: 150px;
}

.navigationwrapper .navigationlinks {
  margin-right: 150px;
  display: flex;
}

.navigationwrapper .navigationlinks a {
  margin: 0 20px;
  padding: 0 20px;
  font-size: 20px;
  font-weight: 300;
  color: black;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.navigationwrapper .navigationlinks > a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.navigationwrapper .navigationlinks a:active {
  color: #00aaff;
}

.navigationwrapper .navigationlinks a:hover {
  color: #00aaff;
}

.servicehover {
  position: relative;
  padding-bottom: 30px !important;
  margin-bottom: -30px !important;
}

.servicehover .navigationicon {
  transition: all 0.5s;
}

.servicehover:hover .navigationicon {
  transform: rotate(180deg);
}

.servicehover:hover .dropdown {
  height: 125px;
  opacity: 1;
}

.dropdown {
  position: absolute;
  width: 170%;
  z-index: 10;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  top: 50px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  background-color: white;
  transition: 0.5s all;
  overflow: hidden;
  opacity: 0;
  height: 0px;
}

.dropdownsingle {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px 0;
  z-index: 10;
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
}

.dropdownsingle a {
  font-size: 17px !important;
}

.homeheadermain {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-bottom: 100px;
  overflow-x: hidden;
}

.homeheadermain.about {
  height: 100%;
}

.homeheadermain .homeheadermaintext.service {
  margin-right: 0;
  animation: fadein 1s forwards;
}

.homeheadermain .homeheadermaintext {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 600px;
  margin-right: -1000px;
  animation: startanimation 1.5s forwards;
  animation-delay: 0.5s;
  z-index: 1;
  opacity: 0;
}

.homeheadermain .homeheadermaintext.about {
  margin-right: 0;
  opacity: 0;
  margin-right: -200px;
  animation: fadein 2s forwards;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes startanimation {
  0% {
    margin-right: -1000px;
  }
  100% {
    margin-right: -350px;
    opacity: 1;
  }
}

.homeheadermain .homeheadermaintext h1 {
  margin: 0;
  font-size: 60px;
}

.homeheadermain .homeheadermaintext h3 {
  color: #2c9cd4;
  font-size: 33px;
  font-weight: 400;
  margin-top: 5px;
  margin-bottom: 40px;
}

.homeheadermain .homeheadermaintext a {
  text-decoration: none;
  background-color: #00aaff;
  color: white;
  padding: 18px 45px;
  border-radius: 6px;
}

.homeheadermain img {
  object-fit: cover;
  margin-right: -350px;
  z-index: 2;
  animation: startimageanimation 0.8s forwards;
}

.homeheadermain .aboutimage {
  margin-right: 0;
  animation: fadein 2s forwards;
}

@keyframes startimageanimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.homemainsection {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #1a1b23;
}

.homebanner {
  width: 70%;
  background-color: #00aaff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: -100px;
  padding: 90px 40px;
}

.homebanner h1 {
  font-size: 35px;
  color: white;
  margin: 0;
}

.homebanner p {
  font-size: 18px;
  color: #8dcfff;
}

.bannericons {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 50px;
}

.bannericons span {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 200px;
}

.mainsectionone {
  width: 70%;
  margin-top: 50px;
}

@media (max-width: 1450px) {
  .mainsectionone {
    width: 100%;
  }
}

.photoandtext {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 100px 0;
  width: 100%;
  flex-wrap: wrap;
}

.photoandtext.reversed {
  flex-direction: row-reverse;
}

.photoandtexttextdiv {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 400px;
  margin: 25px 25px;
}

.photoandtexttextdiv h1 {
  color: white;
  margin: 0;
  font-size: 35px;
}

.photoandtexttextdiv span {
  color: #2c9cd4;
  margin: 0;
  font-size: 18px;
}

.photoandtexttextdiv p {
  color: #7e7e7e;
  margin: 35px 0;
  font-size: 16px;
  line-height: 30px;
}

.photoandtexttextdiv a {
  background-color: #23242d;
  color: white;
  font-size: 16px;
  padding: 20px 48px;
  border-radius: 6px;
  text-decoration: none;
  transition: 0.5s all ease-in-out;
}

.photoandtexttextdiv a:hover {
  background-color: #00aaff;
}

.photoandtext img {
  width: 700px;
  height: 425px;
  object-fit: contain;
  margin: 25px 25px;
}

.homemainsectiontwo {
  background: url(/static/media/headerbackground.d55cdcdf.jpeg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: bottom;
  padding-bottom: 150px;
}

.gallerywrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 75px;
  padding-bottom: 100px;
}

.gallery {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}

.gallery img {
  margin: 10px;
  object-fit: cover;
}

.gallery .firstimage {
  width: 350px;
  height: 700px;
  object-fit: cover;
}

.gallery .secondimage {
  width: 700px;
  height: 700px;
}

.littleimages {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.littleimages .thirdimage {
  height: 340px;
  width: 350px;
}

.littleimages .fourthimage {
  height: 340px;
  width: 350px;
}

.gallerytitle {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: 10px;
}

.gallerytitle h1 {
  margin: 0;
  font-size: 35px;
}

.gallerytitle p {
  font-size: 18px;
  margin-top: 10px;
  color: #2c9cd4;
}

.collapsewrapper {
  display: flex;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
  padding-top: 80px;
}

.collapsewrapper img {
  width: 500px;
  object-fit: contain;
  margin-right: 50px;
}

.collapse {
  width: 500px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-left: 50px;
  padding-top: 60px;
}

.collapsesingle {
  margin: 15px 0;
}

.collapsesingle h4 {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.collapsesingle h4:hover {
  cursor: pointer;
}

.collapsesingle p {
  color: #808080;
  margin-top: 5px;
  margin-left: 60px;
  line-height: 30px;
}

.footer {
  background: #1a1b23;
}

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 100px;
}

.footerbanner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 25px 40px;
  margin-top: -50px;
  width: 70%;
  background: #07a9ff;
}

.footerbanner h2 {
  color: white;
  font-size: 16px;
  width: 100%;
  margin-bottom: 80px;
  padding-bottom: 10px;
  border-bottom: 1px solid #53bbff;
}

.footerbannerimages {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 45px;
  flex-wrap: wrap;
}

.footerbannerimages img {
  width: 100px;
  height: 100px;
  object-fit: contain;
  margin: 0 30px;
}

.footerbannerimages a {
  transition: 0.3s all ease-in-out;
  border-radius: 6px;
  font-family: "League Gothic", sans-serif;
  text-decoration: none;
  color: white;
  opacity: 0.5;
  font-size: 70px;
  margin: 0 15px;
  padding: 10px 16px;
}

.footerbannerimages a:hover {
  background: #26b7ff;
  opacity: 1;
}

.footertext {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 70px;
  width: 70%;
  border-bottom: 1px solid #404148;
  padding-bottom: 50px;
}

.footertextblock {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin: 0 10px;
}

.footertextblock h2 {
  color: #2c9cd4;
  margin: 35px 0;
  font-size: 18px;
  font-weight: 300;
}

.footertextblock a {
  color: white;
  text-decoration: none;
  margin: 5px 0;
  font-weight: 300;
  transition: 0.3s all ease-in-out;
}

.footertextblock a:hover {
  color: #2c9cd4;
}

.socials {
  width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 30px;
}

.networks {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.networklinksdiv {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.networklinksdiv .netlink {
  margin: 0 3px;
  padding: 15px;
  width: 20px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border-radius: 100%;
  color: white;
}

.networklinksdiv .netlink:nth-child(1) {
  background-color: #de4b39;
}

.networklinksdiv .netlink:nth-child(2) {
  background-color: #1cb6ea;
}

.networklinksdiv .netlink:nth-child(3) {
  background-color: #3c5997;
}

.networklinksdiv .netlink:nth-child(4) {
  background-color: #54acee;
}

.networks h2 {
  color: #2c9cd4;
  font-weight: 300;
  font-size: 18px;
}

.stores {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
  flex-wrap: wrap;
}

.stores img {
  width: 170px;
  margin: 5px 5px;
}

.pictureline {
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 100px 35px;
}

.picturelineinner {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  height: 100%;
}

.picturelineinner h2 {
  font-size: 35px;
  color: #1a1b23;
  margin: 5px 0;
  margin-left: 15px;
}

.picturelineinner span {
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 50px;
  margin-left: 15px;
  color: #2c9cd4;
}

.picturelineinnerwrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.picturebox {
  display: flex;
  flex-direction: column;
  width: 310px;
  height: 454px;
  margin: 15px;
}

.picturebox img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.picturebox.service:nth-child(1) img {
  -webkit-filter: hue-rotate(180deg);
          filter: hue-rotate(180deg);
}

.picturebox.service:nth-child(2) img {
  -webkit-filter: hue-rotate(40deg);
          filter: hue-rotate(40deg);
}

.picturebox.service:nth-child(3) img {
  -webkit-filter: hue-rotate(75deg);
          filter: hue-rotate(75deg);
}

.picturetext {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  background-color: #23242d;
  height: 127px;
  width: 100%;
}

.picturetext h2 {
  color: white;
  font-weight: 400;
  font-size: 20px;
  margin: 5px 0;
  margin-left: 20px;
}

.picturetext span {
  font-size: 16px;
  color: #2c9cd4;
  font-weight: 400;
  margin: 0;
  margin-left: 20px;
}

.contactwrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
  padding-bottom: 150px;
}

.textblock1 {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 327px;
  padding: 0 10px;
}

.textblock1 h1 {
  margin: 0;
  color: white;
  font-size: 35px;
}

.textblock1 h3 {
  margin: 0;
  color: #2c9cd4;
  font-weight: 300;
  font-size: 18px;
}

.textblock1 p {
  margin: 0;
  margin-top: 20px;
  font-size: 16px;
  color: #808080;
  max-width: 250px;
  line-height: 25px;
}

.contactwrapper form {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 685px;
}

.contactwrapper form input {
  width: 255px;
  padding: 0 20px;
  height: 50px;
  margin: 10px;
  background-color: rgba(35, 36, 45, 1);
  border: none;
  font-size: 16px;
  color: grey;
}

.contactwrapper form .message {
  width: 100%;
  height: 180px;
  margin: 10px 25px;
  padding: 20px;
  background-color: rgba(35, 36, 45, 1);
  border: none;
  font-size: 16px;
  font-family: "Ubuntu", sans-serif;
  color: grey;
  resize: none;
}

.contactwrapper form .message:focus {
  outline: none;
}

.contactwrapper form .message::-webkit-input-placeholder {
  font-family: "Ubuntu", sans-serif;
}

.contactwrapper form .message:-ms-input-placeholder {
  font-family: "Ubuntu", sans-serif;
}

.contactwrapper form .message::placeholder {
  font-family: "Ubuntu", sans-serif;
}

.contactwrapper form input:focus {
  outline: none;
}

@media (max-width: 1600px) {
  .homeheadermain img {
    width: 60% !important;
    margin-right: -150px;
  }
  .photoandtext img {
    width: 500px;
  }
}

@media (max-width: 1100px) {
  .mainsectionone {
    width: 90%;
  }
  .collapsewrapper {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 0;
  }
  .collapse {
    margin: 0;
    max-width: 95%;
  }
  .collapsewrapper img {
    margin: 0;
    margin-top: 50px;
    width: 300px;
    object-fit: cover;
  }
  .collapsesingle h4 {
    max-width: 100%;
    font-size: 17px;
  }
  .collapsesingle p {
    font-size: 15px;
  }
  .photoandtext img {
    width: 45%;
    height: auto;
    margin: 25px;
  }
  .photoandtext {
    margin: 50px 0;
  }
  .photoandtexttextdiv {
    margin: 0;
  }
  .bannericons {
    flex-wrap: wrap;
  }
  .bannericons span {
    margin: 15px 0;
  }
  .homeheadermain {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    position: relative;
  }
  .homeheadermain .homeheadermaintext {
    position: absolute;
    top: 50px;
    left: 50px;
  }
  .homeheadermain img {
    position: absolute;
    right: 0px;
    bottom: 100px;
    margin-right: 0;
    width: 650px !important;
  }
}

@media (max-width: 560px) {
  .homeheadermain img {
    display: none;
  }
  .homeheadermain .homeheadermaintext {
    top: 15%;
  }
  .homeheadermain .homeheadermaintext h1 {
    font-size: 45px;
    width: 300px;
  }
  .homeheadermain .homeheadermaintext h3 {
    width: 300px;
  }
}

@media (max-width: 1200px) {
  .navigationwrapper img {
    margin-left: 50px;
  }
  .navigationwrapper .navigationlinks {
    margin-right: 50px;
  }
}

@media (max-width: 920px) {
  .mainsectionone {
    width: 90%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .photoandtext {
    width: 90%;
  }
  .photoandtexttextdiv {
    width: 100%;
  }
  .photoandtext img {
    width: 100%;
    height: auto;
    margin: 25px 0;
  }
}

@media (max-width: 1000px) {
  .navigationwrapper img {
    margin-left: 50px;
  }
  .navigationwrapper .navigationlinks {
    display: none;
  }
  .burger {
    display: flex;
    margin-right: 50px;
  }
}

@media (max-width: 1500px) {
  .galleryinner {
    margin: 0 50px;
  }
  .gallery .firstimage {
    width: 275px;
  }
  .gallery .secondimage {
    width: 600px;
  }
}

@media (max-width: 1400px) {
  .gallery .firstimage {
    width: 250px;
  }
  .gallery .secondimage {
    width: 480px;
  }
}

@media (max-width: 1250px) {
  .gallery .firstimage {
    width: 250px;
  }
  .gallery .secondimage {
    width: 250px;
  }
  .littleimages {
    align-items: flex-start;
  }
  .littleimages .thirdimage {
    width: 250px;
  }
  .littleimages .fourthimage {
    width: 250px;
  }
}

@media (max-width: 1100px) {
  .gallery img {
    height: 340px;
    width: 200px;
  }
}

@media (max-width: 930px) {
  .galleryinner {
    width: 90%;
  }
  .gallery {
    flex-direction: column;
  }
  .littleimages {
    width: 100%;
  }
  .gallery img {
    width: 100% !important;
    height: 200px !important;
    margin: 10px 0;
  }
}

@media (max-width: 450px) {
  .gallery img {
    height: 300px !important;
    object-position: center;
  }
}

.mobilemenu {
  padding: 20px 0;
  padding-top: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 20px;
  justify-content: center;
  position: relative;
}

.mobilemenu a {
  font-size: 20px;
  color: black;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 30px;
  margin: 15px 0;
  transition: all 0.5s;
}

.mobilemenu .mobiledropper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 20px;
  margin: 15px 0;
}

.mobiledropdonwn {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  transition: all 0.5s;
}

.closemenu {
  position: absolute;
  top: 20px;
  right: 25px;
}

@media (max-width: 810px) {
  .contactwrapper {
    flex-direction: column;
  }
  .contactwrapper .textblock1 {
    margin: 25px 0;
  }
}

.scroller {
  height: 70px;
  width: 70px;
  border-radius: 10px;
  position: fixed;
  bottom: 15px;
  right: 15px;
  background-color: #23242d;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
}

.scroller:hover {
  cursor: pointer;
}

.mobiledropper:hover {
  cursor: pointer;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

